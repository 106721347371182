import React from 'react'
import { Layout, CareInstructions, Seo } from '../components'

export default function Care() {
  return (
    <Layout>
      <Seo title="Care Instructions" />
      <CareInstructions />
    </Layout>
  )
}
